import Widget           from "lib/widget"
import ImageModal       from 'widgets/inspection/imageModal'

class Image extends Widget {
  bind() {
    bindModal.call(this)
    bindDiscard.call(this)
    bindToggleIncludeReport.call(this)
  }
}

let bindModal = function() {
  console.log(this.root)
  this.root.on('click', 'img', (e) => {
    let imageModal = new ImageModal(e.target.src)
    imageModal.open()
  })
}

let bindToggleIncludeReport = function() {
  let toggleReport = this.root.find('.image-toggle-reportable')

  let url = toggleReport.data('url')

  toggleReport.on('change', 'input', (e) => {
    $.ajax({
      url: url,
      method: "put",
      dataType: 'json'
    }).done((response) => {
      $(e.currentTarget).prop('checked', response.image.included_into_report);
    })
  })
}

let bindDiscard = function() {
  this.root.on('click', '.discard-image', (e) => {
    e.preventDefault();

    let url = e.currentTarget.href;

    swal.fire({
      title:   "Do you want to remove this image?",
      html:   "You cannot undo this action",
      type:   "info",
      showCancelButton: true,
      confirmButtonText: "Delete"
    }).then((Confirm) => {
      $.ajax({
        url: url,
        method: 'PUT',
        dataType: 'json',
        success: (response) => {
        swal.fire({
          title: "Success!",
          type: 'success'
        })
        window.location = response.redirectUrl;
        window.location.reload(true);
        }
      });
    }).catch(() => {
      // We don't need to process any dismissals
    })

  })
}

export default Image;
