import Widget           from "lib/widget"
import AjaxFileForm     from 'servos/ajaxFileForm'
import ImageModal       from 'widgets/inspection/imageModal'

class Image extends Widget {
  bind() {
    let formElem = this.root.find('form')

    let ajaxForm = new AjaxFileForm(formElem)

    ajaxForm.on('success', handle_success.bind(this));
    ajaxForm.on('error', handle_error.bind(this));

    this.root.find('.attachment-field').on('change', (e) => {
      $($(e.currentTarget).closest('form')).find('.image-container').html("<div class='spinner-container'><span class='fa fa-spinner fa-4x fa-spin'></span></div>")
      formElem.submit()

    })

    this.root.on('click', '.image-container', (e) => {
      let imageModal = new ImageModal(e.target.src)
      imageModal.open()
    })

    bindToggleIncludeReport.call(this)
    bindRemoveAttachment.call(this)
  }
}

let handle_success = function(response) {
  this.replaceHtml(response.data.html);
}

let handle_error = function(response) {
  this.replaceHtml(response.jqXHR.responseJSON.formHtml);
}

let bindToggleIncludeReport = function() {
  let toggleReport = this.root.find('.inspection-toggle-included-into-report')

  let url = toggleReport.data('url')

  toggleReport.on('change', 'input', (e) => {
    $.ajax({
      url: url,
      method: "put",
      dataType: 'json'
    }).done((response) => {
      $(e.currentTarget).prop('checked', response.image.included_into_report);
    })
  })
}

let bindRemoveAttachment = function() {
  this.root.find('.inspection-remove-attachment').on('click', (e) => {
    let url = $(e.currentTarget).data('url')

    swal.fire({
      title:  "You want to remove this image?",
      html:   "You can't undo this action",
      type:   "info",
      showCancelButton: true,
      confirmButtonText: "Remove"
    }).then((Confirm) => {
      $.ajax({
        url: url,
        method: 'PUT',
        dataType: 'json',
        success: (response) => {
          this.replaceHtml(response.html);
        }
      });
    }).catch(() => {
      // We don't need to process any dismissals
    })
  })
}

export default Image;
